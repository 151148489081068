import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = {
    isNotFreeSearch: Boolean,
    mailboxFolderId: Number,
    refreshUrl: String,
    onFilterOrSearchView: String
  }

  static targets = [
    "emptyState"
  ]

  connect() {
    document.addEventListener("turbo:before-stream-render", this.beforeRender.bind(this));
    if (this.isNotFreeSearchValue) {
      this._timer = setInterval(function(){
        fetch(this.refreshUrlValue)
      }.bind(this), 10000)
    }
  }

  disconnect() {
    document.removeEventListener("turbo:before-stream-render", this.beforeRender.bind(this));
    if (this.isNotFreeSearchValue) {
      const timer = this._timer;
      if (!timer) return;
      clearInterval(timer);
    }
  }

  selectMessage(e) {
    const currentSelected = document.querySelector(".mailbox-item-selected");
    const mailBoxItems = document.querySelectorAll(".js-mailbox-item");

    mailBoxItems.forEach((item) => {
      item.classList.remove("mailbox-item-selected");
    });

    e.currentTarget.closest(".js-mailbox-item").classList.add("mailbox-item-selected");
  }

  beforeRender(event) {
    const fallbackToDefaultActions = event.detail.render
    const onFilterOrSearchViewProxy = this.onFilterOrSearchViewValue;
    const reOrderPreviewMessagesProxy = this.reOrderPreviewMessages.bind(this);
    const removeEmptyStateProxy = this.removeEmptyState.bind(this);

    event.detail.render = function (streamElement) {
      let performRender = true;
      let isCorrectTarget = streamElement.target.startsWith("preview_message")

      // when the stream is for the mailbox preview messages, and we are currently in a filter view, then skip rendering
      if (isCorrectTarget && onFilterOrSearchViewProxy) {
        performRender = false;
      }

      // find current selected and rember its model id for later
      // is nil if there is currently no message selected (e.g. when you open a mailbox and don't click on any message)
      const currentSelectedElement = document.querySelector(".mailbox-item-selected")
      let currentSelectedModelId;
      if (currentSelectedElement) {
        currentSelectedModelId = currentSelectedElement.dataset.modelId;
      }

      if(performRender) {
        fallbackToDefaultActions(streamElement)
      }
      removeEmptyStateProxy()
      reOrderPreviewMessagesProxy()

      // find current selected by model id and re-add correct class
      if (currentSelectedElement && currentSelectedModelId) {
        const currentSelected = document.querySelector("[data-model-id='" + currentSelectedModelId + "']");
        if (currentSelected) {
            currentSelected.classList.add("mailbox-item-selected");
        }
      }
    }
  }

  removeEmptyState() {
    if(this.hasEmptyStateTarget) {
      this.emptyStateTarget.remove();
    }
  }

  reOrderPreviewMessages(){
    let previewMessagesContainer = document.querySelector(".js-preview-messages")
    let previewMessages = Array.from(previewMessagesContainer.children);

    if (elementsAreSorted(previewMessages)) return

    previewMessagesContainer.innerHTML = "";
    previewMessages.sort(compareElements).forEach((element) => {
      previewMessagesContainer.append(element);
    });
  }

  selectMessageWithKeyboard(e){
    e.preventDefault()

    let currentSelected = document.querySelector(".mailbox-item-selected");

    let targetElement;

    if (currentSelected) {
      if (e.key == "ArrowDown") {
        targetElement = currentSelected?.parentElement?.nextElementSibling?.querySelector(".js-mailbox-item a");
      } else {
        targetElement = currentSelected?.parentElement?.previousElementSibling?.querySelector(".js-mailbox-item a");
      }
    } else {
      targetElement = document.querySelector(".js-mailbox-message-list .js-mailbox-item a");
    }

    if (targetElement) {
      targetElement.closest(".js-mailbox-item").scrollIntoView({ behavior: "smooth"});

      targetElement.click();
    }
  }
}

function elementsAreSorted([ left, ...rights ]) {
  for (const right of rights) {
    if (compareElements(left, right) > 0) return false
    left = right
  }
  return true
}

function compareElements(left, right) {
  return getSortCode(right) - getSortCode(left)
}

function getSortCode(element) {
  return element.getAttribute("data-message-received-at") || 0
}