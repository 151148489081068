import Rails from "@rails/ujs"
import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = {
    formUrl: String
  }

  connect() {
    this.overlay = document.querySelector(".js-card-overlay")
    this.createDraftHandler = this.createDraft.bind(this)
    this.overlay.addEventListener("click", this.createDraftHandler);
    console.log(this.formUrlValue);
  }

  disconnect() {
    this.overlay.removeEventListener("click", this.createDraftHandler);
  }

  createDraft(e) {
    // only handle clicks on the overlay or the close button
    if (
      e.target.classList.contains("js-card-overlay") ||
      e.target.classList.contains("card-close") ||
      e.target.classList.contains("js-save-draft")
    ) {
      const form = document.querySelector('[data-email-composer-target="form"]');
      const formData = new FormData(form);
      const body = formData.get("email_message[outgoing_rich_text_content]")
      const subject = formData.get("email_message[subject]")

      if ( body || subject ) {
        Rails.ajax({
          url: this.formUrlValue,
          type: "POST",
          data: formData
        })
      }

      this.closeCard()
    }
  }

  closeCard() {
    window.cardController.closeCard()
  }
}